import { Box, Chip, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { usePalette } from 'react-palette';
import { useNavigate } from 'react-router-dom';

export const ProductCategoriesItem = ({ category, fontSize = '18px' }) => {
  const navigate = useNavigate();
  const { data, loading, error } = usePalette(category?.picture?.url);

  return (
    <Box
      onClick={() => navigate(`/shop/${category?.slug}`)}
      sx={{
        width: '100%',
        height: '200px',
        position: 'relative',
        borderRadius: '10px',
        overflow: 'hidden !important',
        background: '#fff',
      }}
    >
      <img
        alt={category?.name}
        src={category?.picture?.url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
      />
      <div
        style={{
          width: '100%',
          height: '100%',
          background: `linear-gradient(to top, ${
            data.darkVibrant || '#d25edc'
          } 0%,  transparent 100%)`,
          position: 'absolute',
          bottom: '0',

          left: 0,
        }}
      >
        <Stack
          justifyContent="end"
          sx={{ width: '100%', height: '100%', p: 2, mb: 3 }}
        >
          <Typography
            sx={{
              mb: 0,
              fontSize,

              color: '#fff',
              lineHeight: '1.1',
              fontWeight: 'bold',
              width: '90%',
            }}
          >
            {category?.name}
          </Typography>
          <Box sx={{ mb: 4, mt: 1 }}>
            <Chip
              color="primary"
              size="small"
              sx={{ fontSize: '11px' }}
              label={'Товаров: ' + category?.productCount || 0}
            />
          </Box>
        </Stack>
      </div>
    </Box>
  );
};
