import { useApi } from './useApi';

import { add } from 'date-fns';
import { useCookies } from 'react-cookie';
import { AuthUserFromTgApp } from '../api/AuthApi';
const tg = window.Telegram.WebApp;

export const useTelegram = () => {
  const [cookie, setCookie] = useCookies();

  const AuthUserFromTgAppApi = useApi(AuthUserFromTgApp);

  const init = async () => {
    if (tg?.initDataUnsafe?.user?.id && tg?.initDataUnsafe?.user?.username) {
      AuthUserFromTgAppApi.sendRequest({
        chatId: tg?.initDataUnsafe?.user?.id,
        username: tg?.initDataUnsafe?.user?.username,
      }).then(({ accessToken }) => {
        setCookie('jwt', accessToken, {
          expires: add(new Date(), {
            days: 1800,
          }),
        });
        tg.ready();
      });
    }
  };

  return {
    tg,
    init,
  };
};
