export const BACKEND_URL =
  process.env.REACT_APP_NODE_ENV == 'development'
    ? 'http://localhost:3001'
    : process.env.REACT_APP_CI_ENV == 'prod'
    ? 'https://api.pinkieshop.ru'
    : 'https://api-test.pinkieshop.ru';

export const url = {
  home: '/',
  category: '/shop/:categoryAlias',
  productVariation: '/shop/:categoryAlias/:productAlias/:productVariation',
  cart: '/cart',
  shop: '/shop',
  wishlist: '/wishlist',
  profile: '/profile',
  profileData: '/profile/data',
  policy: '/policy',
  profileOrders: '/profile/order-history',
  profileSupport: '/profile/support',
  profileOfferta: '/contract',
  profileUseRules: '/use-rules',
  profileReturnsRules: '/returns-rule',
  orderDelivery: '/order/:orderId/delivery',
  orderDeliveryCdek: '/order/:orderId/delivery/cdek',
  orderDeliveryCourier: '/order/:orderId/delivery/courier',

  orderProducts: '/order/:orderId/products',
  orderCustomer: '/order/:orderId/customer',
  orderFinal: '/order/:orderId/final',
  orderResult: '/order/:orderId/result',
  search: '/search',
};
